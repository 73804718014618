import React from "react";
import anime from "animejs";
import locationPin from "./assets/map-pin.svg";
import "./App.css";
import { ArrowUpRight } from "react-feather";

export default function App() {
  const targetTranslateY = window.innerHeight - 35;

  const initializeDripAnimation = (selector, delayStart) => {
    anime({
      targets: selector,
      translateY: targetTranslateY,
      delay: anime.stagger(100, { start: delayStart }),
      loop: true,
      easing: "easeInCubic",
    });
  };

  React.useEffect(() => {
    const dripConfigs = [
      { selector: ".drip", delayStart: 50 },
      { selector: ".drip2", delayStart: 175 },
      { selector: ".drip3", delayStart: 500 },
      { selector: ".drip4", delayStart: 750 },
      { selector: ".drip5", delayStart: 1150 },
      { selector: ".drip6", delayStart: 200 },
      { selector: ".drip7", delayStart: 300 },
    ];

    dripConfigs.forEach(({ selector, delayStart }) => {
      initializeDripAnimation(selector, delayStart);
    });
  }, []);

  return (
    <div>
      <div className="parent">
        <div className="one"></div>
      </div>

      <div className="parent">
        <div className="two-left">
          {Array.from({ length: 7 }, (_, i) => (
            <div key={i} className={`drip drip${i + 1}`}></div>
          ))}
        </div>
        <div className="two-main">
          <h1 className="name">Tristan Dardani</h1>
          <div className="location">
            <img className="mapPin" src={locationPin} alt="NYC" />
            <div>NYC</div>
          </div>
          <br />
          <div className="header">Experience:</div>
          <i className="titleText">Charles Schwab</i>
          <div>Software Engineer</div>
          <div>Jan 2023 - Present</div>
          <br />
          <i className="titleText">Charles Schwab</i>
          <div>Software Engineer Intern</div>
          <div>Jun 2022 - Aug 2022</div>

          <br />
          <div className="header">Extras:</div>
          <div>
            <a
              className="link"
              target="_blank"
              rel="noreferrer noopener"
              href="https://leetcode.td31.dev/"
            >
              <i className="titleText">LeetCode Notes</i>
            </a>
            <ArrowUpRight size={16} />
          </div>
          <div>
            Handful of completed LeetCode solutions in Python with notes
          </div>
          <br />
          {/* <div>
            <a
              className="link"
              target="_blank"
              rel="noreferrer noopener"
              href="https://leetcode.td31.dev/"
            >
              <i className="titleText">Book Notes</i>
            </a>
            <ArrowUpRight size={16} />
          </div>
          <div>
            Handful of completed LeetCode solutions in Python with notes
          </div>
          <br /> */}
          <div className="header">Links:</div>
          <div>
            <i>
              <a
                className="link"
                target="_blank"
                rel="noreferrer noopener"
                href="https://www.linkedin.com/in/tristan-dardani-00568099/"
              >
                Linkedin
              </a>
            </i>
            <ArrowUpRight size={16} />
          </div>
          <div>
            <i>
              <a
                className="link"
                target="_blank"
                rel="noreferrer noopener"
                href="https://github.com/td31"
              >
                Github
              </a>
            </i>
            <ArrowUpRight size={16} />
          </div>
        </div>
      </div>
    </div>
  );
}
